import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import SpecialOrderSummary from "../../components/Epharmacy/SpecialOrder/SpecialOrderSummary"

export default ({ location }) => (
  <Layout
    title="Order Summary"
    subtitle={
      <span>
        Please review the details of your order and delivery address.{" "}
        <br className="is-hidden-mobile" /> We will verify your order via SMS
        through the mobile number indicated.
      </span>
    }
    seoTitle="Order Summary"
    process="epharmacy"
    display={{ footer: false, helpCenterBanner: false }}
    underMaintenance={
      process.env.GATSBY_HAS_SPECIAL_ORDER_ENABLED === "disabled"
    }
  >
    <Container isCentered desktop={6} fullhd={6}>
      <SpecialOrderSummary route={location?.pathname} />
    </Container>
  </Layout>
)
