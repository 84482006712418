import moment from "moment"

export const orderTextMessageTemplate = ({ zendeskID }) => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] CarePlus: Your order has been received. Our coordinator will update you within the day during operating hours (Mon - Fri, 8:30AM-4:30PM) or next working day for any further updates. If you do not hear from us within one working day, please email us with your reference number ${zendeskID}. Thank you!`
}

export const specialOrderTextMessageTemplate = ({ zendeskID }) => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] CarePlus: Your order has been received. Our coordinator will update you within the day during operating hours (Mon - Fri, 8:30AM-4:30PM) or next working day for any further updates. If you do not hear from us within one working day, please email us with your reference number ${zendeskID}. Thank you!`
}
